* {
	margin: 0;
	padding: 0;
}

#loader2 {

	top: 50px;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}


#loader3 {
	 pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
html,body,#root {
	background-color: #E5E5E5
}
ul {
	list-style-type: none;
}

a {
 text-decoration: none;
}
